import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'


const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq" id="faqs">

      <Container>
        <div className="text-center">
          <h4 className="heading-h2"><span className="heading-h3 bluecolor">FAQ</span>
            Frequently Asked Questions</h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                What is LocalBitcoins Clone Script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">A LocalBitcoins clone script is a pre-built software solution that is designed for users to trade Bitcoin in a P2P way through an Escrow based system.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                How does LocalBitcoins Clone work?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The LocalBitcoins clone software works by creating a platform that connects buyers and sellers of cryptocurrencies in a peer-to-peer manner. It follows the same operational flow and principles as the original LocalBitcoins platform, providing a secure and efficient environment for cryptocurrency trading.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                What is the estimated time to launch a P2P exchange platform like LocalBitcoins?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">The time required to launch a peer-to-peer (P2P) exchange platform like LocalBitcoins can vary depending on several factors, including the complexity of the desired features, customization requirements, and the chosen development approach. However, by utilizing a pre-built LocalBitcoins clone, you can significantly launch a P2P platform like LocalBitcoins in seven days' time.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                What’s the cost of a local Bitcoin Clone Script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The cost of a LocalBitcoins Clone Script usually ranges from $8,000 to $15,000. It can vary depending on factors such as the features included, customization requirements, additional services offered by the provider, and the level of support provided. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                Why should you choose Coinsclone to build a P2P exchange?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Choosing Coinsclone to build a peer-to-peer (P2P) exchange like LocalBitcoins offers several advantages. Coinsclone is a reputable company with expertise in cryptocurrency exchange solutions. They provide a reliable and customizable LocalBitcoins clone software, allowing you to launch your own secure and feature-rich P2P exchange platform.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection