import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class CaseStudy extends React.Component {


  render() {

    return (
      <section className="banner  casestudy">  
        <div className='casestudy-bg'>
        <div className="container">
            <h2 className='heading-h2 text-center'>Our Recent <span className='bluecolor'>successful Work</span></h2>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-side mb-3 mb-lg-0">
                <StaticImage 
                    src="https://coinsclone.mo.cloudinary.net/images/localbit-new/bitfada-logo.webp"
                    alt="Bitfada Logo"
                    width={302}
                    className='mb-4'
                />
                <ul>
                    <li>Bitfada used our LocalBitcoins Clone Script to build a P2P Exchange that allows users to buy and sell Crypto coins.</li>
                    <li>The Crypto Exchange supports all fiat currencies and offers various payment methods, making it highly demanding.</li>
                    <li>Apart from payment options, Bitfada allows <b>Crypto-Fiat Swapping</b> and supports virtual cards to enhance the experience.</li>
                    <li>Overall, Bitfada is a fast-rising success and is considered to be a highly secure reliable Crypto Exchange.</li>
                </ul>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/localbit-new/bitfada.webp"
                alt="Bitfada"
                width={780}
              />
            </div>
          </div>
          <div className='text-center'>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Get a free demo of our LocalBitcoins clone script </a>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default CaseStudy