import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class WhyShould extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="whyshould pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3"><span className='bluecolor'>Why should</span> you Create a</span>
                P2P Cryptocurrency Exchange Like Localbitcoins?
              </h2>
              <p className="pharagraph head">LocalBitcoins is exclusively known for its peer-to-peer crypto transactions which is a prominent ads-based crypto exchange in the crypto sector. The prominence and user base across the world triggered many budding startups to launch a p2p ads-based exchange like Localbitcoins. Here we share some of the worthy reasons to start a p2p crypto exchange business similar to Localbitcoins.
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="664px" height="623px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/why-should-create-a-p2p-exchange-like-lbc.webp" alt="P2P Cryptocurrency Exchange Like Localbitcoins image1" />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph" >In Localbitcoins, any user can buy or sell cryptocurrencies for fiat currency and vice versa with the ads-based trading option.
                </li>
                <li className="pharagraph" >Localbitcoins exchange comes with top-end security features and functional features that make it unique in the crypto marketplace.
                </li>
                <li className="pharagraph" >This p2p exchange platform is best for beginners who want to trade crypto coins easily.
                </li>
                <li className="pharagraph" >All kinds of identity checks are done through the KYC/AML framework.
                </li>
                <li className="pharagraph" >LocalBitcoins has more than one million active crypto users and had a huge revenue rise of 10%.
                </li>
                <li className="pharagraph" >This exchange provides an escrow-powered p2p system that allows the users to trade cryptos in a secure and fastest way. </li>
                <li className="pharagraph" >LocalBitcoins generated a lucrative ROI with a huge user base. Therefore, creating a p2p crypto exchange similar to LocalBitcoins will help you to gain a greater crypto user base which results in reaping profitable revenue.
                </li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould

