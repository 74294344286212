import React from 'react'



const TechStack = () => {


  return (
    <section className="stack">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 mx-auto text-center">
            <h4 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Technologies Used</span> for</span>
              Our Localbitcoins Clone Script</h4>
            <p className="pharagraph head">We use updated and cutting-edge technology tools for developing the Localbitcoins clone exchange website and mobile application which will be compatible with all major platforms like Android, IOS, and the web.
            </p>
            <div className="quick text-center">
              <img  width="1110px" height="auto" src="https://coinsclone.mo.cloudinary.net/images/binance/technologies-stack.svg" alt="Technologies Stack Image1" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TechStack